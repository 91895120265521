import React from "react";
import "./styles.css";

const Privacy = () => {
  return (
    <div>
      <section id="privacy" className="page privacy-policy">
        <div className="align-center">
          <header className="section-header">
            <h2>Privacy Policy</h2>
          </header>
        </div>
        <div className="privacy-content">
          <p className="effective-date">Effective Date: 09/26/2024</p>
          
          <div className="policy-section">
            <p>Select Corporation ("Company," "we," "our," or "us") is committed to safeguarding the privacy of
            the individuals who visit our website (selectcorp.org) and interact with us through various
            channels, including email, SMS, and phone communications. This Privacy Policy outlines how we
            collect, use, share, and protect your information when you engage with us.</p>
            
            <h3>1. Scope of this Policy</h3>
            <p>This Privacy Policy applies to personal data collected through our website, mobile communications
            (including SMS), and other interactions with Select Corporation. By using our services, visiting our
            website, or opting in to receive communications from us, you consent to the practices outlined in
            this policy.</p>
            
            <h3>2. Information We Collect</h3>
            <p>We may collect various types of information from you, depending on how you interact with us,
            including:</p>
            
            <h4>a. Personal Information:</h4>
            <p>Information you voluntarily provide, such as your:</p>
            <ul>
              <li>Name</li>
              <li>Email address</li>
              <li>Telephone number (including for SMS)</li>
              <li>Business name (if applicable)</li>
              <li>Mailing address</li>
              <li>Other details relevant to our interactions</li>
            </ul>
            
            <h4>b. Automatically Collected Information:</h4>
            <p>When you visit selectcorp.org, certain information may be automatically collected, such as:</p>
            <ul>
              <li>IP address</li>
              <li>Browser type and version</li>
              <li>Time zone settings</li>
              <li>Operating system and platform</li>
              <li>Pages you view on our website</li>
            </ul>
            
            <h4>c. SMS Communications:</h4>
            <p>By opting in to receive SMS messages, you provide your explicit consent for Select Corporation to
            send you communications through text messaging. The content of these messages may relate to:</p>
            <ul>
              <li>Service updates</li>
              <li>Notifications relevant to your interactions with us</li>
              <li>Transactional or operational messages</li>
            </ul>
            
            <h3>3. How We Use Your Information</h3>
            <p>We use your personal information to provide, maintain, and enhance our services. Specific uses of
            your data include:</p>
            <ul>
              <li>Service Delivery: To process and manage inquiries, orders, or requests you make.</li>
              <li>SMS Communications: To send you important updates, notifications, and responses to
              your requests via SMS if you have provided explicit consent.</li>
              <li>Personalization: To improve user experience, ensuring that our communications and
              website offerings are relevant to your interests.</li>
              <li>Analytics and Insights: To analyze website traffic, user behavior, and engagement with our
              content, allowing us to enhance our offerings.</li>
              <li>Security and Compliance: To monitor and enforce compliance with our policies and to
              fulfill legal obligations.</li>
            </ul>
            
            <h3>4. Sharing of Your Information</h3>
            <p>Select Corporation does not sell, rent, or trade your personal information to third parties for
            commercial or marketing purposes. We may share your information in the following limited
            instances:</p>
            
            <h4>a. Service Providers:</h4>
            <p>We engage trusted third-party vendors who assist in delivering services (such as hosting providers,
            communication platforms, and analytics services). These service providers are bound by
            confidentiality agreements and may only use your data as necessary to fulfill their obligations to us.</p>
            
            <h4>b. Legal Compliance:</h4>
            <p>We may disclose personal information when required by law, or if we believe that such action is
            necessary to:</p>
            <ul>
              <li>Comply with legal obligations or governmental requests.</li>
              <li>Enforce our terms and conditions.</li>
              <li>Protect our rights, privacy, safety, or property.</li>
            </ul>
            
            <h4>c. Business Transfers:</h4>
            <p>In the event of a merger, acquisition, or sale of all or part of our assets, your personal information
            may be transferred as part of the transaction. In such an event, we will notify you of any changes to
            the handling of your personal information.</p>
            
            <h3>5. Data Security</h3>
            <p>We implement a variety of security measures to maintain the safety of your personal information.
            These include, but are not limited to:</p>
            <ul>
              <li>Secure servers</li>
              <li>Encryption technologies</li>
              <li>Access control measures</li>
            </ul>
            <p>Despite our efforts, no system is completely secure. We cannot guarantee the absolute security of
            your information.</p>
            
            <h3>6. Your Rights and Choices</h3>
            <p>You have several rights concerning your personal information, including the right to:</p>
            <ul>
              <li>Access the data we hold about you.</li>
              <li>Rectify inaccurate information.</li>
              <li>Delete your data (subject to certain legal exceptions).</li>
              <li>Restrict or object to the processing of your data in certain cases.</li>
              <li>Withdraw consent for receiving SMS communications at any time by replying "STOP" to any
              message or by contacting us directly.</li>
            </ul>
            <p>To exercise these rights, please contact us using the information provided in the Contact Us
            section below.</p>
            
            <h3>7. SMS Opt-In and Opt-Out</h3>
            <p>By opting in to receive SMS messages from us, you consent to the processing of your mobile
            number for communication purposes. You can opt out of SMS messages at any time by replying
            "STOP" to any message or contacting us at dave@selectcorp.org or 425-242-6641. Standard
            messaging rates may apply depending on your mobile carrier.</p>
            
            <h3>8. Cookies and Tracking Technologies</h3>
            <p>Our website may use cookies or similar tracking technologies to improve user experience and
            gather insights on website performance. You may configure your browser settings to refuse cookies;
            however, some features of our site may not function as intended without them.</p>
            
            <h3>9. Data Retention</h3>
            <p>We will retain your personal information for as long as necessary to fulfill the purposes outlined in
            this Privacy Policy unless a longer retention period is required by law. Upon request, we will
            securely delete or anonymize personal information, provided it is no longer necessary for legitimate
            business or legal purposes.</p>
            
            <h3>10. International Data Transfers</h3>
            <p>If you are accessing our services from outside the United States, please note that your information
            will be transferred to and processed in the United States. By using our services, you consent to the
            transfer and processing of your information in accordance with this Privacy Policy.</p>
            
            <h3>11. Changes to this Privacy Policy</h3>
            <p>We reserve the right to update or modify this Privacy Policy at any time. Any changes will be
            reflected with a new "Effective Date" at the top of this page. We encourage you to review this policy
            periodically for any updates.</p>
            
            <h3>12. Contact Us</h3>
            <p>If you have any questions or concerns regarding this Privacy Policy, or if you wish to exercise your
            rights regarding your personal information, please contact us at:</p>
            <div className="contact-us">
              <p>Select Corporation</p>
              <p>Email: office@selectcorp.org</p>
              <p>Phone: 425-242-6641</p>
              <p>Website: selectcorp.org</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Privacy; 