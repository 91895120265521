import React, { useEffect } from "react";

import "./style.css";

const Slider = () => {
  useEffect(() => {
    const slides = document.querySelectorAll(".slide");
    const next = document.querySelector("#next");
    const prev = document.querySelector("#prev");
    
    // Only proceed if slider elements exist
    if (!slides.length || !next || !prev) return;

    const auto = true; // Auto scroll
    const intervalTime = 5000;
    let slideInterval;

    const nextSlide = () => {
      const current = document.querySelector(".current");
      if (!current) return; // Guard clause

      current.classList.remove("current");
      if (current.nextElementSibling && current.nextElementSibling.classList.contains("slide")) {
        current.nextElementSibling.classList.add("current");
      } else {
        slides[0].classList.add("current");
      }
      setTimeout(() => current.classList.remove("current"));
    };

    const prevSlide = () => {
      const current = document.querySelector(".current");
      if (!current) return; // Guard clause

      current.classList.remove("current");
      if (current.previousElementSibling && current.previousElementSibling.classList.contains("slide")) {
        current.previousElementSibling.classList.add("current");
      } else {
        slides[slides.length - 1].classList.add("current");
      }
      setTimeout(() => current.classList.remove("current"));
    };

    // Add event listeners
    next.addEventListener("click", () => {
      nextSlide();
      if (auto) {
        clearInterval(slideInterval);
        slideInterval = setInterval(nextSlide, intervalTime);
      }
    });

    prev.addEventListener("click", () => {
      prevSlide();
      if (auto) {
        clearInterval(slideInterval);
        slideInterval = setInterval(nextSlide, intervalTime);
      }
    });

    // Auto slide
    if (auto) {
      slideInterval = setInterval(nextSlide, intervalTime);
    }

    // Cleanup function
    return () => {
      if (slideInterval) {
        clearInterval(slideInterval);
      }
      // Remove event listeners
      if (next) next.removeEventListener("click", nextSlide);
      if (prev) prev.removeEventListener("click", prevSlide);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <div>
      <div className="slider">
        <div className="slide current">
          <div className="content">
            <h1>Make The Right Choice</h1>
            <p>
              Choose us, be <span className="text-primary">SELECT</span>
            </p>
          </div>
        </div>
        <div className="slide">
          <div className="content">
            <h1>Various Freights</h1>
            <p>We transport various goods, depending on the customer's needs</p>
          </div>
        </div>
        <div className="slide">
          <div className="content">
            <h1>Save Time</h1>
            <p>Prompt delivery as a result of professional team</p>
          </div>
        </div>
      </div>
      <div className="buttons">
        <span id="prev">
          <i className="fas fa-chevron-left"></i>
        </span>
        <span id="next">
          <i className="fas fa-chevron-right"></i>
        </span>
      </div>
    </div>
  );
};

export default Slider;
