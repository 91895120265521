import React from "react";

import "./styles.css";
const About = () => {
  return (
    <div>
      <section id="about" className="page">
        <div className="align-center">
          <header className="section-header">
            <h2>ANYWHERE, ANYTIME, ON ROAD</h2>
          </header>
        </div>
        <div className="section-about">
          <div className="row">
            <div className="column">
              <p>
                Welcome to Select Corporation, a family-owned, interstate
                trucking company dedicated to delivering excellence across the
                nation. Our journey began on the road: I started as a truck
                driver, progressed to an owner-operator, and for over ten years,
                we've steadily expanded our fleet. Our commitment to growth is
                matched by our dedication to safety, reliability, and customer
                satisfaction.
              </p>
              <p>
                With a solid foundation built through hands-on experience at
                every level of the industry, we know the intricate workings of
                this business inside and out. The trucking industry remains a
                cornerstone of the U.S. economy, generating over <strong>$875 billion in revenue in 2022</strong> and transporting over <strong>72% of all freight</strong>. We
                understand the immense responsibility of moving freight safely,
                on time, and efficiently, and we're equipped to meet the demands
                of a fast-paced, ever-changing market.
              </p>
              <h3><strong>Comprehensive services across multiple divisions</strong></h3>
              <p>
                To better serve our clients' diverse needs, Select Corporation has expanded into three specialized
                divisions:
              </p>
              <ul className="services-list">
                <li><strong>Open Deck Division:</strong> Handling RGN, Step Deck, Flatbed, and Conestoga loads, offering versatility in transporting oversized or irregular freight.</li>
                <li><strong>Reefer Division:</strong> Designed for temperature-controlled shipments, ensuring that perishable goods are transported under precise conditions.</li>
                <li><strong>Dry Van Division:</strong> Focusing on general freight with reliable and secure transport solutions for a wide range of goods.</li>
              </ul>
              <p>
                We value and support every driver and owner-operator within our
                team. We recognize the challenges of the trucking industry, and
                we are committed to making the journey rewarding, both
                financially and professionally. We invest in the latest
                technology and software to ensure we provide the highest level
                of safety, efficiency, and compliance with all regulations.
              </p>
              <p>
                Our professional dispatchers keep operations smooth, securing
                optimal rates for local loads, dedicated lanes, or long-haul
                routes.
              </p>
              <h3><strong>Join our team or partner with us</strong></h3>
              <p>
                Whether you're a driver or owner-operator looking for a company that prioritizes safety and
                growth, or a broker seeking a reliable and professional partner, Select Corporation is here for you. Our team
                is dedicated to supporting our partners with personalized service, 24/7 communication, and
                industry-leading standards.
              </p>
              <p>
                Reach out to us today, and let's keep America moving—together.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
