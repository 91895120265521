import React from "react";
import "./styles.css";

const Services = () => {
  return (
    <div>
      <section id="services" className="page services">
        <div className="align-center">
          <header className="section-header">
            <h2>Services</h2>
          </header>
        </div>

        <div className="row">
          <div className="cards">
            <div className="cards-sections">
              <h3>Reefer</h3>
              <p>
                Temperature-controlled freight requires more attention, our
                professional drivers will set and make sure that proper
                temperature is maintained during transportation.
              </p>
            </div>
            <div className="img-1"></div>
          </div>
          <div className="cards">
            <div className="cards-sections">
              <h3>DryVan</h3>
              <p>
                We use only up to 5 years old Dry Van Trailers, equipped with E
                Tracks, that keeps your freight safe.
              </p>
            </div>
            <div className="img-2"></div>
          </div>
          <div className="cards">
            <div className="cards-sections">
              <h3>OPEN DECK</h3>
              <p>
                If your freight requires side loading or unloading, our Flatbed
                Division is equipped to assist. We ensure that all cargo is
                securely fastened, and, if necessary, provide tarping for added
                protection.
              </p>
            </div>
            <div className="img-3"></div>
          </div>
          <div className="cards">
            <div className="cards-sections">
              <h3 className="heading-tertiary">RGN</h3>
              <p>
                If your freight requires specialized handling for oversized or
                heavy loads, our RGN (Removable Gooseneck) Division is ready to
                assist. We ensure safe and secure transport, with careful
                attention to proper loading, unloading, and compliance with all
                regulatory requirements.
              </p>
            </div>
            <div className="img-4"></div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Services;
